import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { env } from '@env/dev.env'
import { AuthData, Credentials } from '@interfaces/auth.interface'
import { BehaviorSubject, catchError, retry, throwError } from 'rxjs'
import { ToastService } from './toast.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private api_url = env.api_url
  private auth$ = new BehaviorSubject<boolean>(false)
  private tokenName = 'PPAT' // Punto PYMES Auth Token

  constructor(private http: HttpClient, private srvToast: ToastService) {}

  connHandler = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      // A client-side or network error occurred
      this.srvToast.error({
        position: 'top-left',
        title: `Error ${error.status}`,
        message: 'Se perdió la conexión',
      })
    }
    return throwError(() => error)
  }

  get isAuth$() {
    return this.auth$.asObservable()
  }

  // TOKEN
  saveToken(authData: AuthData) {
    const auth = authData.token !== undefined
    if (auth) localStorage.setItem(this.tokenName, JSON.stringify(authData))
    if (this.isTokenSaved) this.auth$.next(auth)
  }
  get isTokenSaved() {
    return !!localStorage.getItem(this.tokenName)
  }
  recoverToken() {
    const token = localStorage.getItem(this.tokenName)
    return token && (JSON.parse(token) as AuthData)
  }

  login(credentials: Credentials) {
    return this.http
      .post<{ data: AuthData }>(`${this.api_url}auth/login`, credentials)
      .pipe(retry(1), catchError(this.connHandler))
  }

  logout(): void {
    localStorage.removeItem(this.tokenName)
    this.auth$.next(false)
  }
}
