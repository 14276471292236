export const env = {
  api_url: 'https://talentrack.app/prod/api/web/',
  firebase: {
    apiKey: 'AIzaSyBMQO_-DorUmmPdGDS0VxlpNAtdzsyuC_Q',
    authDomain: 'talenttrack-a756a.firebaseapp.com',
    projectId: 'talenttrack-a756a',
    storageBucket: 'talenttrack-a756a.appspot.com',
    messagingSenderId: '417211543312',
    appId: '1:417211543312:web:691cc5e86acfcc7cf9ff95',
  },
}
