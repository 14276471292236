import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'
import { ToastPositionType } from 'primeng/toast'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  messageService: MessageService = new MessageService()

  setService(messageService: MessageService) {
    this.messageService = messageService
  }

  success(config: {
    title: string
    message: string
    duration?: number
    position?: ToastPositionType
  }) {
    this.messageService.add({
      contentStyleClass: 'text-lg',
      severity: 'success',
      key: !!config.position ? config.position : 'bottom-left',
      life: !!config.duration ? config.duration : 3000,
      detail: config.message,
      summary: config.title,
    })
  }

  info(config: {
    title: string
    message: string
    duration?: number
    position?: ToastPositionType
  }) {
    this.messageService.add({
      contentStyleClass: 'text-lg',
      severity: 'info',
      key: !!config.position ? config.position : 'bottom-left',
      life: !!config.duration ? config.duration : 3000,
      detail: config.message,
      summary: config.title,
    })
  }

  warn(config: {
    title: string
    message: string
    duration?: number
    position?: ToastPositionType
  }) {
    this.messageService.add({
      contentStyleClass: 'text-lg',
      severity: 'warn',
      key: !!config.position ? config.position : 'bottom-left',
      life: !!config.duration ? config.duration : 3000,
      detail: config.message,
      summary: config.title,
    })
  }

  error(config: {
    title: string
    message: string
    duration?: number
    position?: ToastPositionType
  }) {
    this.messageService.add({
      contentStyleClass: 'text-lg',
      severity: 'error',
      key: !!config.position ? config.position : 'bottom-left',
      life: !!config.duration ? config.duration : 3000,
      detail: config.message,
      summary: config.title,
    })
  }
}
